console.log('components');


import { select } from '../js/components/select';
select();

import { parallax } from '../js/components/parallax';
parallax();

import { form } from '../js/components/form';
form();
