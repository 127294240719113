export const parallax = () => {

    let fog = document.querySelector('.placeholder__background-fog');
    let logo = document.querySelector('.placeholder__background-logo');
    let godzilla = document.querySelector('.placeholder__background-godzilla');
    
    if ( fog && logo && godzilla ) {
        window.addEventListener('mousemove', function(e) {
            let x = e.clientX / window.innerWidth;
            let y = e.clientY / window.innerHeight;  
            
            if ( window.innerWidth > 844 ) {
                fog.style.transform = 'translate(-' + x * 2 + '%,' + y * 2 + '%)';
                logo.style.transform = 'translate(+' + x * 4 + '%, -' + y * 4 + '%)';
                godzilla.style.transform = 'translate(-' + x * 3 + '%, -' + y * 3 + '%)';
            }
        });
    }
    

}