export const select = () => {

    
    const selects = document.querySelectorAll(".select");
    const formText = document.querySelector('.js-text-change');
    const formSendTo = document.querySelector('.js-send-to');

    document.addEventListener("click",function(){
        document.querySelector(".select__list_open")?.classList.toggle("select__list_open");
    });

    function change_text(value) {
        if ( value == 'Technical Support' )         { formSendTo.value = "support@hitzgames.com" ; formText.innerHTML = 'Contact us if you encounter any technical issues or bugs in the game.' }
        if ( value == 'Gameplay Feedback' )         { formSendTo.value = "feedback@hitzgames.com" ; formText.innerHTML = 'We value your feedback! Share your thoughts on the gameplay experience.' }
        if ( value == 'Business Development' )      { formSendTo.value = "bizdev@hitzgames.com" ; formText.innerHTML = 'Interested in business opportunities or collaborations? Reach out to our business development team.' }
        if ( value == 'Partnership Proposals' )     { formSendTo.value = "partnerships@hitzgames.com" ; formText.innerHTML = 'Explore partnership opportunities with us. Contact our partnership team.' }
        if ( value == 'Advertising Opportunities' ) { formSendTo.value = "advertising@hitzgames.com" ; formText.innerHTML = 'Inquire about advertising opportunities within our games or on our platforms.' }
        if ( value == 'Marketing Inquiries' )       { formSendTo.value = "marketing@hitzgames.com" ; formText.innerHTML = 'For marketing collaborations and inquiries, get in touch with our marketing team.' }
        if ( value == 'Media and Press' )           { formSendTo.value = "press@hitzgames.com" ; formText.innerHTML = 'For media inquiries and press information, please contact our press team.' }
        if ( value == 'General Questions' )         { formSendTo.value = "info@hitzgames.com" ; formText.innerHTML = 'For any other questions or information, feel free to contact us.' }
    }

    if ( selects ) {
        for (let select of selects ){
            let options = select.querySelectorAll(".select__option");
            let value = select.querySelector(".select__value");
            let list = select.querySelector(".select__list");
    
            value.addEventListener("click",function(e){
    
                
                document.addEventListener("click",function(){
                    document.querySelector(".select__list_open")?.classList.remove("select__list_open");
                });
                
                if ( !select.querySelector(".select__list_open") ) {
                    setTimeout(function(){
                        list.classList.toggle("select__list_open");
                    }, 100);
                }
                
            });
            
            for (let option of options) {
                option.addEventListener("click",function(){
                    value.value = option.dataset.value;
                    list.classList.toggle("select__list_open");
                    if ( formText && formSendTo ) {
                        change_text(value.value);
                    }
                });
            }
        
        }
    
        
        
    }



    
    
    
    
    
    
    
    


}